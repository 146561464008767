.main__footer  {
    background: #A74C37;
    color: #fff;
    padding: 7rem 0;
    margin:  0 auto;
  }
  

  .main__footer .links__section{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main__footer .links__section .links__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
  }

  .main__footer .links__section .links__wrapper .logo__section{
    max-width: 34rem;

  }

  .main__footer .links__section .links__wrapper .logo__section img {
    height: 6rem;
    max-width: 100%;
    object-fit: contain;
  }

  .main__footer .links__section .links__wrapper .logo__section .footer__desc {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 1rem;
    color: #fff;
    margin-bottom: 2rem;

  }

  

  
  .main__footer .links__section .links__wrapper .foot__links .footul_head {
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 22px;
  }


  
  .main__footer .links__section .links__wrapper .foot__links ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 1.8rem;
    gap: 10px;
    list-style: none;
  }
  
  .main__footer .links__section .links__wrapper .foot__links ul li a {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 33px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease;
  }
  .main__footer .links__section .links__wrapper .foot__links ul li a:hover {
    color: #F2D331 ;
    cursor: pointer;
  }
  
  .main__footer .links__section .links__wrapper .foot__links .support__section {
    margin-top: 18px;
  }
  
  .main__footer .links__section .links__wrapper .foot__links .support__section a {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    text-decoration: none;
    line-height: 22px;
    color: #fff;

  }
  
  .main__footer .links__section .links__wrapper .foot__links .support__section .social__icons {
    margin-top: 15px;
  }
  
  .main__footer .links__section .links__wrapper .foot__links .support__section .social__icons a {
    font-size: 23px;
    opacity: 0.8;
    margin-right: 15px;
    text-decoration: none;
  }
  .main__footer .links__section .links__wrapper .foot__links .support__section .social__icons a svg {
    stroke-width: 1.5px;
    color: #fff;
  }
  @media screen and (max-width: 1100px) {
    .main__footer .links__section {
      width: 100% ;
      justify-content: start;
      align-items: center;
    }
    .main__footer .links__section .links__wrapper {
        width: 100%;
    }
  }
  
  @media screen and (max-width: 765px) {
    .main__footer .links__section .links__wrapper .foot__links {
      margin: 40px 0;
      margin-bottom: 0;
    }
    .main__footer .links__section .links__wrapper {
      flex-direction: column;
    }
    .main__footer .links__section .links__wrapper .logo__section .footer__desc {
      margin-bottom: 0;
    }
    .main__footer .links__section .links__wrapper .logo__section{
      max-width: 100%;
    }
  }
  