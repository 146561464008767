.table__wrapper {
  width: calc(100% - 30rem);

}

.table__wrapper .table__container table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  border-radius: 0.6rem;

}

thead tr {
  border-bottom: 1px solid #d0d5dd;
  background: #fff;
}

thead tr th {
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  padding: 1rem 2rem;
  font-style: medium;
}

tbody tr td {
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 400;
  padding-left: 2rem;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.table__wrapper .table__container .pagenation__wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

}
.table__wrapper .table__container .pagenation__wrapper .pagenations{
  display: flex;
  gap: 1rem;
  align-items: center;
}

.table__wrapper .table__container .pagenation__wrapper .pagenations .pagenation__circle{
  all: unset;
  min-height: 4.5rem;
  min-width: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  background: #fff;
  border-radius: 50%;
  font-weight: 500;
  cursor: pointer;
}
.table__wrapper .table__container .pagenation__wrapper .pagenations .pagenation__circle.active{
  background: #A74C37;
  color: #fff;
}

.name__td {
  display: flex;
  gap: 1.2rem;
  padding: 2rem;
  align-items: center;
}

.img__wrapper {
  height: 3.5rem;
  width: 3.5rem;
  overflow: hidden;
  border-radius: 50%;

}

.img__wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.not__booked__text{
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 1.8rem;
  color: #212121;
  font-weight: 500;
}
.action__wrapper {}

.action__wrapper .action__container {
  display: flex;
  gap: 0.8rem;
}

.action__wrapper .action__container .acton__box {
  display: flex;
  gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  align-items: center;
  border-radius: 0.4rem;
}

.action__wrapper .action__container .print {
  background: #1194F71F;
  color: #2196F3;
}

.action__wrapper .action__container .view {
  background: #02B6B31F;
  color: #1DB9AA;


}

.action__wrapper .action__container .acton__box svg {
  height: 1.6rem;
  width: 1.6rem;
  stroke-width: 1.5px;
}

.action__wrapper .action__container .acton__box span {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.appontment__date__td .time{
  color: #009EFB;
  font-size: 1.4rem;
}

@media screen and (max-width:1100px) {
  .table__wrapper .table__container{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
}
@media screen and (max-width:1000px) {
  .table__wrapper {
    width: 100%;
  }
  
}
