.main{
    padding: 7rem 0;
}
.main__content{
    display: grid;
    grid-template-columns:2fr 1fr;
    gap: 2rem;
}
.main__content .left {
    box-shadow: 0px 0px 15px 0px #0000001A;
    background: #fff;
    padding: 3rem;

}
.main__content .left .pagination__buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.main__content .left .pagination__buttons .common__paginate__btn{
    font-size: 1.7rem;
    background: #A74C37;
    padding: 0.8rem 1.5rem;
    border-radius: 0.6rem;
    cursor: pointer;
    color: #fff;
}
.main__content .left .pagination__buttons .common__paginate__btn.disable{
    background: #d5d0dd;
    color: #667285;
    cursor: not-allowed;
    pointer-events: none;
}
.main__content .left .left__blog__content h2{
    all: unset;
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #252525;
    margin-bottom: 2rem;

}
.blog__detail__boxes{
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}
.blog__detail__boxes .blog__info__box{
    display: flex;
    align-items: center;
    color: #A74C37;
    gap: 0.2rem;
}
.blog__detail__boxes .blog__info__box svg{
    height: 1.5rem;
    width: 1.5rem;
    stroke-width: 2px;
    /* margin-top: -2px; */
}
.tags__info__wrapper{
    display: flex;
    gap: 0.5rem;
}
.blog__detail__boxes .blog__info__box span{
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        /* background: #757575; */
}

.blog__detail__boxes .blog__info__box .tag__span{
    background: #FFFDD0;
    color: #252525;
    padding: 0 6px;
    border-radius: 0.5rem;
    font-weight: 400;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    cursor: pointer;

}
.single__blog__mini__desc{
    margin-bottom: 2rem;
    font-size: 1.8rem;

}
.single__blog__image__wrapper{
    width: 100%;
    margin-bottom: 2rem;
    max-height: 70%;
}
.single__blog__image__wrapper img{
    width: 100%;
    max-height: 70%;
    height: 350px;
    object-fit: cover;
}
.main__content .left .left__blog__content .blog__all__content p,.main__content .left .left__blog__content .blog__all__content ,.main__content .left .left__blog__content .blog__all__content p strong {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #757575;
    text-align: justify;
}

.main__content .left .left__blog__content .blog__all__content p:empty {
    height: 28px;
}
.main__content .left .left__blog__content .blog__all__content ul {
    list-style-type: disc;
    padding: 1rem;
}
.main__content .left .left__blog__content .blog__all__content ol {
    list-style-type: decimal;
    padding: 1.2rem;
}

.main__content .left .left__blog__content .blog__all__content a {
    color: #0092cc;
}
.main__content .left .left__blog__content .blog__all__content figure{
    margin: 2rem 0;
    border-radius: 0.4rem;
}
.main__content .left .left__blog__content .blog__all__content figcaption{
    display: none;
    
}
.main__content .left .left__blog__content .blog__all__content img{
    max-height: 70rem;
    height: 40rem;
    width: 100%;
    object-fit: cover;

}
.main__content .right{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.main__content .right .box{
    box-shadow: 0px 0px 15px 0px #0000001A;
    background: #fff;
    padding: 2rem;
}
.main__content .right .box .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    padding-left: 1rem;
    border-left: 4px solid #A74C37;

}
.main__content .right .box .categories__container, .main__content .right .box .tags__container{
    margin-top: 2rem;
    padding-left: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.main__content .right .box .categories__container .single__category{
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    width: fit-content;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    cursor: pointer;
    background-color: #FFFDD0;
}
.main__content .right .box .categories__container .single__category:hover{
    color: #A74C37;
}
.main__content .right .box .tags__container{
    display: flex;
    flex-wrap: wrap;
    
}
.main__content .right .box .tags__container .single__tag{
    background: #F6F7FB;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #333333;
    cursor: pointer;
}

.blog__single__cards{
    display: flex;
    flex-direction: column;
    /* gap: 3rem; */

}
.blog__single__cards .no__blog__posts{
    font-size: 3.2rem;
    color: #252525;
}
.blog__single__cards .blog__single__card{
    display: flex;
    gap: 2rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #d0d5dd;
}
.blog__single__cards .blog__single__card:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;

}
.blog__single__cards .blog__single__card .card__image {
    height: 240px;
    min-width: 24rem;
    cursor: pointer;

}

.blog__single__cards .blog__single__card .card__image  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.blog__single__cards .blog__single__card .title__description {
    width: 100%;

}
.blog__single__cards .blog__single__card .title__description .blog__tages{
    display: flex;
    gap: 0.6rem;
    flex-wrap: wrap;

}
.blog__single__cards .blog__single__card .title__description .tag__date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.blog__single__cards .blog__single__card .title__description .tag__date .posted__date{
    font-size: 12px;
    color: #757575;
    padding: 0.4rem 1rem;
    background: #F6F7FB;
    width: fit-content;
    border-radius: 0.4rem;
    margin: 0.5rem 0;

}
.blog__single__cards .blog__single__card .title__description .tag__date .blog__tag {
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    width: fit-content;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    cursor: pointer;
    background-color: #FFFDD0;

}
.blog__single__cards .blog__single__card .title__description  .blog__title{
    all: unset;
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #252525;

}
.blog__single__cards .blog__single__card .title__description  .blog__title:hover{
    cursor: pointer;
    color: #A74C37;
}
.blog__single__cards .blog__single__card .title__description  .mini__desc{
    font-size: 14px;
    font-weight: 400;
    color: #252525;

}
.blog__author {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}
.blog__author span svg{
    height: 1.4rem;
    width: 1.4rem;
    margin-top: -2px;
    margin-right: 2px;


}
.blog__author span{
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: #A74C37;
    
}
.times{
    font-size: 1.2rem;
    color: #333333;
}

.attachment-gallery {
    display: grid !important;
    grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    gap: 2rem !important;
}

@media screen and (max-width:1000px) {
    .blog__single__cards .blog__single__card{
        flex-direction: column;
    }
    
    
}
@media screen and (max-width:767px) {
    .main__content{
        display: flex;
        flex-direction: column-reverse;
    }
    .main__content .right .box .categories__container{
        display: flex;
        flex-wrap: wrap;
    }
    .main__content .right .box .categories__container .single__category{
        width: fit-content;
        padding: 0.4rem 1rem;
        background: #F6F7FB;
        border-radius: 0.4rem;
    }
    .blog__single__cards .no__blog__posts{
        font-size: 2.2rem;
    }
    .blog__detail__boxes{
        gap: 1rem;
    }
}