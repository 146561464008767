.apt__wrapper {}

.apt__wrapper .goback {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #667285;
    margin: 4rem;
}

.apt__wrapper .goback svg {
    height: 2rem;
    width: 2rem;
    stroke-width: 1.5px;
}

.apt__wrapper .goback span {
    font-size: 1.7rem;
    font-weight: 500;
}

.apt__wrapper .apt__infos {
    background: #fff;
    box-shadow: 0px 0px 15px 0px #0000001A;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 4rem;
    padding-top: 0;
}

.apt__header {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;

}

.apt__header .image {
    height: 6rem;
    margin-right: 1rem;

}

.apt__header .image img {
    height: 6rem;
    max-width: 90%;
    object-fit: contain;
}

.apt__header .apt__right p,
.apt__header .apt__right p {
    all: unset;
    display: block;
    color: #272B41;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.5px;
    /* 150% */
}

.apt__header .apt__right p span,
.apt__header .apt__right p span {
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
}

.apt__location__patient__info {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.apt__location__patient__info .apt__location {
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    /* 150% */
    /* max-width: 30rem; */

}

.apt__location__patient__info .patient__info h3 {
    all: unset;
    display: block;
    color: #272B41;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    /* 150% */
    text-align: right;

}

.apt__location__patient__info .patient__info p {
    all: unset;
    display: block;
    color: #757575;
    font-size: 15px;
    text-align: right;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
    /* 150% */
}

.apt__details {
    margin-top: 1rem;
}

.apt__details h4 {
    color: #272B41;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    /* 150% */
}

.apt__details .apt__detail p {
    all: unset;
    display: block;
    color: #272B41;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
    /* 150% */

}

.apt__details .apt__detail p span {
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
}

.apt__desc {
    margin-top: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.apt__desc h5 {
    all: unset;
    display: block;
    color: #272B41;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    /* 150% */
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

}

.apt__desc p {
    all: unset;
    display: block;
    color: #757575;
    font-size: 15px;
    font-style: normal;
    padding: 1rem 1.5rem;
    font-weight: 500;
    line-height: 22.5px;
    /* 150% */
}

.apt__other__info {
    padding: 10rem 0;
    padding-bottom: 6rem;
}

.apt__other__info h4 {
    all: unset;
    display: block;
    color: #272B41;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.6px;
    /* 120% */

}

.apt__other__info p {
    all: unset;
    display: block;
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
    /* 150% */

}

.apt__infos__footer {
    text-align: center;
}

.apt__infos__footer p {
    all: unset;
    display: block;
    color: #757575;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
    /* 150% */

}

.apt__infos__footer a {
    all: unset;
    text-decoration: none;
    display: block;
    text-align: center;
    cursor: pointer;
    color: #272B41;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.5px;
}

.download__button {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 2rem;
    right: 4rem;

}

.download__button .download__button__width {
    width: 20rem;
    margin-right: 4rem;
    margin-bottom: 4rem;
}

@media screen and (max-width:520px) {
    .apt__wrapper .apt__infos {
        padding: 1rem;
    }

    .apt__header .apt__right p,
    .apt__header .apt__right p,.apt__location__patient__info .apt__location ,.apt__details .apt__detail p ,.apt__details .apt__detail p span,.apt__desc p ,.apt__other__info p ,.apt__infos__footer p ,.apt__infos__footer a{
        font-size: 1.2rem;
        line-height: 15.5px;
    }
    .apt__location__patient__info .patient__info h3,.apt__details h4 ,.apt__desc h5 ,.apt__other__info h4 {
        font-size: 1.6rem;
        line-height: 18px;
    }
    .apt__location__patient__info .patient__info p {
        font-size: 1.2rem;
        line-height: 20px;
    }
    .apt__details .apt__detail{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .apt__other__info {
        padding-top: 6rem;
    }
    .download__button{
        right: 0;
        bottom: 0;
    }
    .download__button .download__button__width {
        margin: 0;
        margin-right: 2rem;
    }

}