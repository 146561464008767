.profile__dash__wrapper{
    background: #e8e8e8;

}
.profile__dash__wrapper .profile__dash__container{
    display: flex;
    grid-template-columns: 1fr 2fr;
    padding: 3rem 0;
    gap: 2rem;

}
@media screen and (max-width:1000px) {
    .profile__dash__wrapper .profile__dash__container{
        flex-direction: column;
    }
    
}