.confirm {
    font-size: 11.3px;
    font-weight: 700;
    line-height: 11.25px;
    text-align: center;
    padding: 0.4rem 0.8rem;
    color: #26AF48;
    background: #0FB76B1F;
    width: fit-content;
}
.cancel{
    font-size: 11.3px;
    font-weight: 700;
    line-height: 11.25px;
    text-align: center;
    padding: 0.4rem 0.8rem;
    color: #E63C3C;
    background: #F211361F;
    width: fit-content;


}
.pending{
    font-size: 11.3px;
    font-weight: 700;
    line-height: 11.25px;
    text-align: center;
    padding: 0.4rem 0.8rem;
    color: #F39C12;
    background: #FF98001F;
    width: fit-content;
}