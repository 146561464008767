
.foot__bottom__wrapper .foot__bottom__container{
    padding: 1.5rem 0;
    background: #A74C37;

}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-top: 0.5px solid rgba(255, 255, 255, 0.2);
    padding-top: 2.4rem;

}

.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__left p,.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__left p a{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
    text-decoration: none;
}

.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right{
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    align-items: center;
}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right .social__icons{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    
}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right .social__icons a{
    display: block;
    cursor: pointer;
    
}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right .social__icons a svg{
    height: 2rem;
    width: 2rem;
    color: #fff;
    
}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right .foot__bottom__sublinks{
    display: flex;
}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right .foot__bottom__sublinks p{
font-size: 1.2rem;
color: #fff;
font-weight: 300;
line-height: 12px;
letter-spacing: 0.4000000059604645px;
cursor: pointer;

    
}
.foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right .foot__bottom__sublinks .bar{
    background-color: #fff;
    margin: 0 1.5rem;
    height: 1.5rem;
    width: 1px;      
}
@media screen and (max-width:1075px) {
    .foot__bottom__wrapper .foot__bottom__container .foot__bottom__content{
        justify-content: center;
        align-items: center;
        gap: 1rem;

    }
    
}
@media screen and (max-width:500px) {
    .foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__right{
        gap: 1rem;
        justify-content: center;
        align-items: center;

    }
    .foot__bottom__wrapper .foot__bottom__container .foot__bottom__content .foot__bottom__left p{
        text-align: center;
    }
    
}