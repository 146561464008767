.dr-card-wrapper{
    padding: 2.5rem;
    border: 1px solid #d0d5dd;
    display: flex;
    gap: 1rem;
}

.dr-card-wrapper .dr-left{
    display: flex;
    gap: 1.5rem;
    
}

.dr-card-wrapper .dr-left .dr-img-wrapper{
    height: 15rem;
    width: 15rem;
    border-radius: 10px;
    overflow: hidden;
    min-height: 15rem;
    min-width: 15rem;
    
}

.dr-card-wrapper .dr-left .dr-img-wrapper img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}

.dr-card-wrapper .dr-left .dr-info .name{
    all: unset;
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #2E3842;

    
}


.dr-card-wrapper .dr-left .dr-info .worked-in{
    all: unset;
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    margin-top: 5px;
    color: #51565D;
    width: 80%;

    
}


.dr-card-wrapper .dr-left .dr-info .ratings{
    display: flex;
    gap: 5px;
    margin-top: 8px;
    margin-bottom: 1rem;
    align-items: center;
    
}

.dr-card-wrapper .dr-left .dr-info .ratings svg{
    color: #F4C150;
    
}
.dr-card-wrapper .dr-left .dr-info .ratings span{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #272B41;
    
    
}


.dr-card-wrapper .dr-left .dr-info .box{
    display: flex;
    gap: 5px;
    margin-bottom: 1rem;
    
}

.dr-card-wrapper .dr-left .dr-info  .box svg{
    height: 2rem;
    width: 2rem;
    stroke-width: 1.5px;
    color: #4E4852;
    
}

.dr-card-wrapper .dr-left .dr-info .box span{
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    color: #51565D;

    
}
.dr-card-wrapper .dr-left .dr-info .experties {
    display: flex;
    gap: 1rem;

}
.dr-card-wrapper .dr-left .dr-info .experties span {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}



.dr-card-wrapper .dr-right{
    
}
.dr-card-wrapper .dr-right .common-box{
    display: flex;
    gap: 1rem;
    align-items: center;
    
}
.dr-card-wrapper .dr-right .common-box svg{ 
    height: 2rem;
    width: 2rem;
    stroke-width: 1.5px;
    
}
.dr-card-wrapper .dr-right .common-box span{
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #4E4852;

    
}

.dr-card-wrapper .dr-right .buttons {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;
    cursor: pointer;
}

.dr-card-wrapper .dr-right  .buttons button{
    all: unset;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 1px;
    text-align: center;
    padding: 1.2rem 4rem;
    text-transform: uppercase;
    white-space: nowrap;
}

.dr-card-wrapper .dr-right  .buttons .book{
    border: 2px solid #A74C37;
    background: transparent;
    color: #A74C37;
    
    
}

.dr-card-wrapper .dr-right .buttons .profile{
    background: #A74C37;
    color: #fff;
    
}

.dr-card-wrapper .dr-left .dr-info  .role{ 
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    margin-top: 0.6rem;
    color: #51565D;
    } 
.dr-card-wrapper .dr-left .dr-info  .designation{
        font-weight: 500;
        margin-top: 0;
     }
@media screen and (max-width:1370px) {

    .dr-card-wrapper{
        flex-direction: column;
    }
}
@media screen and (max-width:1000px) {

    .dr-card-wrapper .dr-left{
        flex-direction: column;
    }
    .dr-card-wrapper .dr-left .dr-img-wrapper{
        width: 100%;
        height: 24rem;
    }

    
}