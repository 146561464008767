.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal.active {
    opacity: 1;
    visibility: visible;
}

.modal .modal__content {

    max-height: calc(100vh - 30px);
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    transition: inherit;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
    padding: 2.4rem;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 8px;
}

.modal .modal__content::-webkit-scrollbar {
    display: none;
}

.modal.active .modal__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.modal .modal__header {
    font-size: 1.7rem;
    font-weight: 500;
    margin: 0.5rem 0;
    margin-top: 1rem;

}

.modal .modal__close {
    position: absolute;
    top: 12px;
    right: 2.12rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #7f7f7f;
}

.modal .modal__close:hover {
    color: #000;
}

.modal__body {
    width: 50rem;

}

@media screen and (max-width: 480px) {
    .modal__body {
        width: 31rem;

    }
}