 .doctor__card {
     padding: 1.6rem;
     /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); */
     border: 1px solid #d0d5dd;
     border-radius: 0.5rem;
     margin-right: 2rem;


 }

 .doctor__card .doctor__img {
     height: 25rem;
     width: 100%;
     margin-bottom: 1.4rem;

 }

 .doctor__card .doctor__img img {
     height: 100%;
     width: 100%;
     object-fit: cover;

 }

 .doctor__card .doctor__name {
     display: flex;
     gap: 0.4rem;
     align-items: center;
 }

 .doctor__card .doctor__name p {
     font-size: 17px;
     font-weight: 500;
     line-height: 20.4px;
     color: #161616;
 }

 .doctor__card .doctor__name .check__icon__wrapper {
     height: 1.7rem;
     width: 1.7rem;
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
     background: #28A745;

 }

 .doctor__card .doctor__name .check__icon__wrapper svg {
     height: 15px;
     width: 15px;
     stroke-width: 1.5px;
     color: #fff;

 }

 .doctor__card .role {
     display: block;
     font-size: 14px;
     font-weight: 400;
     line-height: 19.5px;
     margin-top: 0.6rem;
     color: #51565D;
 }

 
 .ratings{
    display: flex;
    gap: 5px;
    margin-top: 8px;
    margin-bottom: 1rem;
    align-items: center;
    
}

.ratings svg{
    color: #F4C150;
    
}
.ratings span{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #272B41;
    
    
}

 .doctor__card .designation {
     font-weight: 500;
     margin-top: 0;
 }

 .doctor__card .doc__rating {
     display: flex;
     align-items: center;
     margin: 0.8rem 0;
 }

 .doctor__card .doc__rating .stars {
     display: flex;
     align-items: center;
     gap: 0.4rem;

 }

 .doctor__card .doc__rating .stars svg {
     color: #F4C150;
     height: 1.8rem;
     width: 1.8rem;
     fill: #F4C150;

 }

 .doctor__card .doc__rating span {
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     color: #51565D;

 }


 .doctor__card .box {
     display: flex;
     align-items: center;
     gap: 0.4rem;
     color: #51565D;
     margin-bottom: 0.8rem;

 }

 .doctor__card .box svg {
     height: 1.6rem;
     width: 1.6rem;
 }

 .doctor__card .box span {
     font-size: 13px;
     font-weight: 400;
     line-height: 19.5px;

 }

 .doctor__card .buttons__wrapper {
     display: flex;
     margin-top: 1.6rem;

 }

 .doctor__card .buttons__wrapper button {
     all: unset;
     padding: 0.7rem;
     width: 50%;
     font-size: 13px;
     font-weight: 500;
     line-height: 19.5px;
     text-align: center;
     cursor: pointer;

 }

 .doctor__card .buttons__wrapper .book__Now {
     background: #A74C37;
     color: #fff;


 }

 .doctor__card .buttons__wrapper .view__profile {
     color: #A74C37;
     border: 2px solid #A74C37;

 }