.topup {
    position: fixed;
    bottom: 15px;
    right: 15px;
    padding: 10px 20px;
    background: #A74C37;
    color: #fff;
    font-size: 25px;
    border: none;
    outline: none;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0px 0px 10px #00000026;
    z-index: 10;
    cursor: pointer;
}

.main-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hamburger-icon {
    display: none;

}

.sm-responsive-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-top {
    background: #f9f9f9;
}

.main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.header-wrapper {
    position: sticky;
    top: 0;
    z-index: 100;
}

.header-wrapper .inner {
    background: #fff;
}

.main-menu .navigation .menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

}

.main-menu .navigation .menu li {
    /* margin-right: 15px; */
    position: relative;
}

.main-menu .navigation .menu li a {
    margin-right: 15px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 25px 12px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    color: #000000;
}

.container-wrapper .inner {
    border-bottom: 1px solid #d0d5dd;
}

.main-menu .login-register-btn {
    all: unset;
    font-weight: 500;
    margin-right: 10px;
    font-weight: 400;
    font-size: 13px;
    padding: 13px 25px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    background: #A74C37;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

}


.main-menu .login-register-btn:hover {
    background-color: #7d2814;
}

.lg-logo {
    display: block;
    height: 60px;
    max-width: 90%;
    object-fit: contain;

}


.navigation .menu li.active a {
    color: #A74C37;
    background-color: #f2eae8;
    border-bottom: 1px solid #A74C37;

}

.user-toggle-btn {
    all: unset;
    position: relative;
}

.user-toggle-btn:hover {
    background: #A74C37;
    border-radius: 50%;

}

.user-toggle-btn .user-avatar {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A74C37;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
}

.user-toggle-btn  .user-image-avatar{
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

}
.hover-image-disable:hover{
    background: none;
}
.user-toggle-btn  .user-image-avatar img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.user-toggle-btn .user-action-dropdown {
    position: absolute;
    border: 1px solid #d0d5dd;
    border-radius: .8rem;
    background: #f8f8f8;
    /* top: 1rem; */
    right: 0;
    margin-top: 1rem;
}

.user-toggle-btn .user-action-dropdown span {
    font-size: 1.4rem;
    display: block;
    padding: 1.2rem;
    border-bottom: 1px solid #d0d5dd;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.user-toggle-btn .user-action-dropdown span svg {
    height: 1.6rem;
    width: 1.6rem;
    stroke-width: 1.5px;
    margin-right: 0.5rem;
}

.logout-btn {
    all: unset;

    border-radius: 8px;
    background: #A74C37;
    padding: 10px 30px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    display: none;
    align-items: center;
}

.logout-btn svg {
    margin-right: 6px;

}



.close-navbar{
    display: none;
}
.close-navbar svg{
    padding-right: 2rem;
    color: #121212;
    stroke-width: 1.5px;
    cursor: pointer;
}
.logo-and-cross-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
}
.logo-and-cross-btn .sm-logo{
    display: block;
    height: 60px;
    max-width: 90%;
    object-fit: contain;
}
@media screen and (max-width:1225px) {
    .main-menu{
        width: 81%;
    }
    .main-menu .navigation .menu li a {
        padding: 1.2rem 0.5rem;
    }

    
}

@media screen and (max-width:1000px) {
    .hamburger-icon {
        display: flex;
        gap: 15px;

    }
    .close-navbar{
        display: flex;
    }

    .hamburger-icon>div {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        /* background: #d5d5dd; */
        background: #b63e22;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease;
        cursor: pointer;
    }

    .hamburger-icon>div:hover {
        background: #7d2814;
        color: #fff;

    }

    .sm-header-logo {
        width: unset;
    }

    .hamburger-icon>div svg {
        width: 20px;
        stroke-width: 1.5px;
    }

    .sm-responsive-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 0;
    }

    .handle-main-menu-wrapper {
        position: fixed;
        min-height: 100vh;
        top: 0;
        left: 0;
        width: 270px;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        background: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding-left: 15px;
        margin-left: 0;
        transform: translateX(-3000px);
        transition: 0.3s ease;

    }

    .main-menu-wrapper {
        position: fixed;
        min-height: 100vh;
        top: 0;
        left: 0;
        width: 270px;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        background: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding-left: 15px;
        margin-left: 0;
        transform: translateX(-3000px);
        transition: 0.3s ease;
        transform: translateX(0);
        z-index: 1;
    }

    .main-menu-wrapper .nav {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .main-menu {
        /* width: 100%; */
        padding-top: 1.2rem;
    }

    .main-menu .navigation .menu {
        flex-direction: column;
        align-items: start;
    }

    .main-menu .navigation .menu li a {
        padding: 1.2rem 2rem;
    }

    .hide-in-sm-device {
        display: none;
    }

    .logout-btn {
        display: flex;
        margin-top: 1.2rem;
    }
    .main-menu .navigation .menu li {
        margin-right: 0;
    }
    .main-menu .login-register-btn {
        /* margin-top: 20rem; */
        /* width: calc(100% - 50px - 2rem); */
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.2rem;
        padding: 1rem 3rem;
    }
    .logo-and-cross-btn{
        display: flex;
    }
    .main-menu .login-register-btn .hide-register-in-sm{
        display: none;

    }

}