.profile__left__wrapper{
    background: #fff;
    border-start-end-radius: 0.6rem;
    width: 30rem;

}

.profile__left__wrapper .profile__left__container .profile__left__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;


}
.profile__left__wrapper .profile__left__container .profile__left__header .img__wrapper{
    box-shadow: 0 0 15px 0 #0000001a;
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    overflow: hidden;
  
    
}
.profile__left__wrapper .profile__left__container .profile__left__header .img__wrapper .profile__dash__no__image{
    height: 100%;
    width: 100%;
    font-size: 3.2rem;
    font-weight: 600;
    color: #fff;
    background: #A74C37;
    display: flex;
    align-items: center;
    justify-content: center;

}
.profile__left__wrapper .profile__left__container .profile__left__header .img__wrapper img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}
.profile__left__wrapper .profile__left__container .profile__left__header .user__name{
    all: unset;
    font-size: 17px;
    font-weight: 500;
    line-height: 20.4px;
    text-align: center;
    color: #272B41;
    margin-top: 1rem;
    margin-bottom: 1rem;


}
.profile__left__wrapper .profile__left__container .profile__left__header .box{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;

}
.profile__left__wrapper .profile__left__container .profile__left__header .box svg{ 
    height: 1.6rem;
    width: 1.6rem;

}
.profile__left__wrapper .profile__left__container .profile__left__header .box span{ 
        font-size: 13px;
        font-weight: 400;
        line-height: 15.6px;
        color: #757575;
}
.profile__ul{
    list-style: none;
    display: flex;
    flex-direction: column;
}
.profile__ul a,.profile__ul li{
    text-decoration: none;
    cursor: pointer;
    color: #757575;
    font-size: 1.6rem;
}
.profile__ul a li.active,.profile__ul li.active{
    color: #A74C37;
}

.profile__ul a:hover,.profile__ul li:hover{
    color:#A74C37 ;
}
.profile__ul a li,.profile__ul li{
    display: flex;
    align-items: center;
    padding: 1.2rem 3rem;
    gap: 0.5rem;
    border-bottom: 1px solid #d0d5dd;
}
.profile__ul a li:first-child{
    border-top: 1px solid #d0d5dd;
    
}
.profile__ul li:last-child{
    border-bottom: none;
    border-top: 1px solid #d0d5dd;
}
.profile__ul a li svg,.profile__ul li svg{
    height: 1.8rem;
    width: 1.8rem;
}

@media screen and (max-width:1000px) {
    .profile__left__wrapper{
        width: 100%;
    }
    
}