.dr-detailer-card-wrapper {
    padding: 7rem 0;
}

.dr-detailer-card-wrapper .left-right-section {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    gap: 2rem;
    display: flex;
    /* gap: ; */
    justify-content: space-between;
}
.dr-detailer-card-wrapper .drcard-width-handle{
   min-width: 60rem;
   box-sizing: border-box;

}

.tabs-section {
    margin-top: 3rem;
    padding: 2rem;
    border: 1px solid #d0d5dd;

}

.dr-detailer-card-wrapper .tabs-wrapper {
    display: flex;
    /* justify-content: space-between; */
    gap: 8rem;
    border-bottom: 1px solid #d0d5dd;


}

.dr-detailer-card-wrapper .tabs-container {
    display: flex;
    justify-content: space-between;
}

.dr-detailer-card-wrapper .heading {
    font-size: 21.73px;
    font-weight: 600;
    line-height: 26.08px;
    color: #272B41;
    margin-top: 2rem;

}

.dr-detailer-card-wrapper .sub-description {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #272B41;
    margin-top: 1rem;

}

.tab {
    cursor: pointer;
    font-size: 18.11px;
    font-weight: 600;
    line-height: 27.16px;
    text-align: center;
    font-weight: 600;

}

.activeTab {
    color: #6f6ff1;
    padding-bottom: 1.8rem;
    border-bottom: 1px solid #A74C37;
    color: #A74C37;
}

.book-your-appointment-wrapper {
    border: 1px solid #d0d5dd;
    width: calc(100% - 60rem);
}

.book-your-appointment-wrapper .book-apointment-container {
    padding: 2rem;
}

.book-your-appointment-wrapper .book-apointment-container h5 {
    font-size: 22px;
    color: #121212;
}

.book-your-appointment-wrapper .book-apointment-container p {
    font-size: 14px;
    color: #757575;
}

.books-form form input {
    margin: 0.5rem 0;
}

.books-form form .disableBtn {
    /* cursor: not-allowed; */
    background: #656582;

}
/* .slick-track{
    margin-left: unset !important;
    margin-right: unset !important;
} */

.books-form form .not-allow {
    cursor: not-allowed;
}

.books-form form .time-slots {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.books-form form .time-slots .booking-time {
    padding: 0.4rem 0.8rem;
    border-radius: 0.8rem;
    border: 1px solid #d0d5dd;
    font-size: 1.6rem;
    color: #212121;
    cursor: pointer;
}

.books-form form .time-slots .selected-time {
    background: #A74C37;
    color: #fff;
}

.books-form form .time-slots .disabled-time-slot {
    background: #e9e9e9;
    /* pointer-events: none; */
    cursor: not-allowed;
}

.books-form form .time-slots .pending-time-slot {
    background: #F29339;
    /* pointer-events: none; */
    cursor: not-allowed;
    color: #000;

}

.books-form form .slots-book-color-info {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.books-form form .slots-book-color-info {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.books-form form .slots-book-color-info .color-info {
    padding: 0.4rem 1rem;
    font-size: 1.2rem;
    border-radius: 0.4rem;
    margin: 0.8rem 0;
}

.books-form form .slots-book-color-info .confirmed-slot {
    background: #e9e9e9;
}

.books-form form .slots-book-color-info .pending-slot {
    background: #F29339;
    color: #000;
}

.books-form form .slots-book-color-info .availabel-slot {
    border: 1px solid #d0d5dd;

}


.desc-card p,
.desc-card,
.desc-card p strong {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #757575;
    text-align: justify;
}

.desc-card p:empty {
    height: 28px;
}

.desc-card ul {
    list-style-type: disc;
    padding: 1rem;
}

.desc-card ol {
    list-style-type: decimal;
    padding: 1.2rem;
}

.desc-card a {
    color: #0092cc;
}

.desc-card figure {
    margin: 2rem 0;
    border-radius: 0.4rem;
}

.desc-card figcaption {
    display: none;

}

.desc-card img {
    max-height: 70rem;
    height: 40rem;
    width: 100%;
    object-fit: cover;

}
.upcoming-dates-tabs-wrapper h3{
    margin: 0.5rem 0;
    font-size: 16px;
    
}
.upcoming-dates-tabs-wrapper .noUpcoming{
    border-bottom: 1px solid #d0d5dd;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.upcoming-dates-tabs{
    display: flex;
    gap: 1rem;
}

.upcoming-dates-tabs .date-tab {
    all: unset;
    padding: 0.5rem 1.5rem;
    border: 1px solid #d0d5dd;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 1.5rem;
}
.upcoming-dates-slider-container {
    position: relative;
    width: 100%;
  }
  
  .upcoming-dates-slider {
    transition: transform 0.5s ease-in-out;
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 2rem;
    position: relative;
    padding-bottom: 0.5rem;
  }
  .upcoming-dates-slider::-webkit-scrollbar{
    width: 7px;
    height: 7px;

  }
  .upcoming-dates-slider::-webkit-scrollbar-thumb{
    -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #bababa;
    
  }
  .upcoming-dates-slider::-webkit-scrollbar-track{
    background-color: #d4d4d4;
  
      -webkit-border-radius: 10px;
      border-radius: 10px;
    
  }
  
  .date-tab {
    all: unset;
    padding: 10px 15px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 1.4rem;
    transition: background-color 0.3s;
    white-space: nowrap;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

 .date-tab:hover {
    background: #A74C37;
    color: #fff;
}
.active-tab{
    /* border: 1px solid transparent; */
    background: #A74C37;
    color: #fff;
}


@media screen and (max-width:1200px) {
    .dr-detailer-card-wrapper .left-right-section {
        flex-direction: column;
    }
    .book-your-appointment-wrapper{
        width: 100%;
    }

}
 
@media screen and (max-width:767px) {
    .dr-detailer-card-wrapper .drcard-width-handle{
        min-width: unset;
    }
    
}

@media screen and (max-width:500px) {
    .tab {
        font-size: 1.3rem;
    }

    .dr-detailer-card-wrapper .heading {
        font-size: 1.4rem;
        font-weight: 500;

    }

    .dr-detailer-card-wrapper .sub-description {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: unset;
        margin-top: 1rem;
    }

    .dr-detailer-card-wrapper .tabs-wrapper {
        gap: unset;
        justify-content: space-between;
    }

}


/* Responsive styles */
/* @media (max-width: 1200px) {
    .slide {
      flex: 1 0 33.33%; 
    }
  }
  
  @media (max-width: 768px) {
    .slide {
      flex: 1 0 50%; 
    }
  }
  
  @media (max-width: 480px) {
    .slide {
      flex: 1 0 100%; 
    }
  } */