.gallery__wrapper{
    padding: 7rem 0;
}

.gallery__wrapper .gallery__content__container .gallery__content h3{
    all: unset;
    display: block;
    font-family: "Poppins";
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    color: #212529;
    /* margin-bottom: 2rem; */
    color: #7D2814;
    /* background: #F6F3FB; */
    border-left: 4px solid #A74C37;
    padding-left: 1rem;
    
}

.gallery__wrapper .gallery__content__container .gallery__content .album__boxes{
    margin-top: 2.4rem;    
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    position: relative;
    /* color: #656582; */
    margin-bottom: 3.6rem;
}

.gallery__wrapper .gallery__content__container .gallery__content .album__boxes .album{
    overflow: hidden;
    border-radius: 0.4rem;
    text-decoration: none;
    display: block;
     
}

.gallery__wrapper .gallery__content__container .gallery__content .album__boxes .no__album__text,.no__videos__text{
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #212529;

}


.gallery__wrapper .gallery__content__container .gallery__content .album__boxes .album img{
    /* max-height: 80%; */
    max-height: 30rem;
    min-height: 30rem;
    width: 100%;
    object-fit: cover;   
    cursor: pointer; 
}

.album__name__wrapper{
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    display: block;
    overflow: hidden;
}

.album__name__wrapper .overlay{
    content: "";
    position: absolute;
    /* height: 100%; */
    width: calc(100% - 6rem);
    background: #A74C37;
    bottom: 0;
    /* height: 0; */
    transition: 0.3s ease;
    cursor: pointer;
    padding: 3rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease;
    
}
.album__name__wrapper:hover  .overlay{
    background: #7D2814;
}

.album__name__wrapper .albun__name{
  
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    outline: none;
    cursor: pointer;

}
.loadMore__section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.loadMore__section .loadmore__button{
    width: 12rem;
}
.loadMore__section .loadmore__button button:disabled{
    cursor: not-allowed;
    background: #656582;
}
@media screen and (max-width:1024px) {
    .gallery__wrapper .gallery__content__container .gallery__content .album__boxes{
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        gap: 1rem;
    }

    
}