.save__changes__button{
    width: fit-content;
    padding: 1.2rem 3rem;
    
}
.profile___form__wrapper{
    background: #fff;
    padding: 2rem;
    border-radius: 0.6rem;
    width: calc(100% - 30rem);
}
.profile___form__wrapper .pic__change{
    display: flex;
    gap: 1.5rem;

}
.profile___form__wrapper .pic__change .pic__wrapper{
    box-shadow: 0 0 15px 0 #0000001a;
    height: 10rem;
    width: 10rem;
    overflow: hidden;
    border-radius: 50%;
    
}
.user__letter__avatar{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    font-size: 3.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A74C37;
    color: #fff;
}

.profile___form__wrapper .pic__change .pic__wrapper img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}
@media screen and (max-width:1000px) {
    .profile___form__wrapper{
        width: 100%;
        padding: 0;
    }
     .profile__form__container{
         padding: 2rem;
     }
     .profile___form__wrapper .pic__change{
        padding: 2rem;
        padding-bottom: 0rem;
     }
}
