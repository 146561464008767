.album__wrapper{
    padding: 7rem 0;
}
.album__wrapper .album__container .album__content h3{
    all: unset;
    display: block;
    font-family: "Poppins";
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    color: #212529;
    margin-bottom: 4rem;
    

}
.no-scroll {
    overflow: hidden;
  }
  #ReactSimpleImageViewer{
    z-index: 110 !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }
.album__wrapper .album__container .album__content .album__images{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;

}
.album__wrapper .album__container .album__content .album__images .album__image{
    overflow: hidden;
    border-radius: 0.6rem;
}
.album__wrapper .album__container .album__content .album__images .album__image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.3s ease;
}
.album__wrapper .album__container .album__content .album__images .album__image:hover img{
   
    transform: scale(1.2);
}

@media screen and (max-width:1024px) {
    .album__wrapper .album__container .album__content .album__images{
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        gap: 1rem;
    }

    
}
.handle__album__image__modal__width{
      background-color: unset !important;
}
.modal__image img{
    height: 100%;
    width: 100%;
}

.handle__album__image__modal__width  .modal__close{
    background: #A74C37;
    border-radius: 50%;
    color: #fff  !important;
    right: 1rem !important;
}

@media screen and (max-width:800px) {
    .handle__album__image__modal__width{
        /* width: 80%;
        height: 28rem; */
    }
    
}