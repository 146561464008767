.notfound__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
}
.notfound__wrapper .not__found__img img{
    height: 320px;
    width: 400px;
    max-width: 90%;
}
.notfound__wrapper .page__notfound__text{
    font-size: 18px;
    margin-bottom: 1rem;
    font-weight: 500;
}
.notfound__wrapper .goto__home__btn{
    width: 15rem;

}