.loader-div{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.loader {
    width:50px;
    height:50px;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#A74C37);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:animate 1s infinite linear;
  }
  @keyframes animate {to{transform: rotate(1turn)}}