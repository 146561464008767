


.topbar-wrapper .topbar-container{
    background: #A74C37;

}

.topbar-wrapper .topbar-container .topbar-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;


}
.topbar-wrapper .topbar-container .topbar-content .topbar-left-section .top-link,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section .top-contact{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    list-style: none;


}
.topbar-wrapper .topbar-container .topbar-content .topbar-left-section .top-link li,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section .top-contact li {
    display: flex;
    gap: 0.8rem;
    align-items: center;

}
.topbar-wrapper .topbar-container .topbar-content .topbar-left-section .top-link li svg,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section .top-contact li svg{
    height: 1.6rem;
    width: 1.6rem;
    stroke-width: 1.5px;
    color: #fff;

}
.topbar-wrapper .topbar-container .topbar-content .topbar-left-section .top-link li span ,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section .top-contact li span,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section .top-contact li a{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width:1000px) {
    .topbar-wrapper .topbar-container .topbar-content{
        flex-wrap: wrap;
        gap: 1rem;
    }
    .topbar-wrapper .topbar-container .topbar-content .topbar-left-section .top-link,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section .top-contact{
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .topbar-wrapper .topbar-container .topbar-content .topbar-left-section,.topbar-wrapper .topbar-container .topbar-content .topbar-right-section {
       
        width: 100%;
        flex-wrap: wrap;
    }
    
}
@media screen and (max-width:767px) {
    .topbar-wrapper .topbar-container .topbar-content .topbar-left-section .top-link{
        display: none;
    }
    .topbar-wrapper .topbar-container .topbar-content{
        gap: 0;
    }
    .topbar-wrapper{
        display: none;
    }
    
}

