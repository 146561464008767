
.contact__form{
    /* background: #e8e8e8; */
    padding: 5rem 0;
    display: flex;
    background: #fff ;
}
.cf__container{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 9rem;

}

.contact__textarea{

    height: 15rem;
    max-height: 15rem;
    overflow-y: auto;
    resize: none;
    font-size: 1.4rem;
    resize: none;
    padding: 1.2rem;
    border: none;
    border: 1px solid #d0d5dd;
    outline: none;
    border-radius: 6px;
    width: calc(100% - 2.4rem);
    margin-top: 0.5rem;
}

.register__form__width{
   width: 100%;
    background: #fff;
    padding: 3rem;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
    border: 1px solid #d0d5dd;
}

.auth__wrapper .auth__container h3{
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color:#121212;

}
.auth__text {
    color: #212121;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}
.form__row{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.handle__margin__form__row{
    margin-top: 0;
}
.input__group{
    display: flex;
    flex-direction: column;
}
.input__group.disable input{
    border: 1px solid #bababa;
    background: #f9f9f9;
    
}
.group {
    position: relative;
}
.passwordToggle , .confirmPasswordToggle ,.confirmPassword{
    position: absolute;
    top: 53%;
    right: 10px;
    cursor: pointer;
}
.passwordToggle svg,.confirmPassword{
    color: #D0D5DD;
    background-color: transparent;
}
label {
    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    display: block;
}
label span {
  color:  rgb(219, 70, 60);
  font-size: 1.5rem;
}

input ,select{
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 12px;
    width: calc(100% - 24px);
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #121212;
 
}
select{
    width: 100%;
    background: #fff;
}
input.is-invalid{
    border: 1px solid rgb(219, 70, 60);
}
.input__group input::placeholder{
    color: #8E97A9;

}
.forgot{
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    margin-top: 10px;
}
.forgot span {
        color:#121212;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        text-decoration: none;
        cursor: pointer;

}
.dont__text{
    color: #121212;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-top: 20px;
}
.dont__text span{
    color: #1BA5D3;
    font-weight: 500;
    cursor: pointer;
}
button{
    border-radius: 8px;
    background: #A74C37;
    padding: 10px 0;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;

}
button:hover{
    background-color: #7D2814;
}
button.disabled{
    cursor: not-allowed;
}
.cf__left__section h3{
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-left: 4px solid #A74C37;
    padding-left: 1rem;
    margin-bottom: 1rem;
}
.cf__left__section .social__icons {
    margin-top: 15px;
  }
  
  .cf__left__section .social__icons a {
    font-size: 23px;
    opacity: 0.8;
    margin-right: 15px;
    text-decoration: none;
    color: #000;
  }
  .cf__left__section .social__icons a svg {
    stroke-width: 1.5px;
  }

  .contact__infos{
    display: flex;
    margin-top: 7rem;
    flex-direction: column;
    gap: 1.2rem;

  }
  .contact__infos .cf__info{
    display: flex;
    align-items: center;
    gap: 1.2rem;
    text-decoration: none;
  } 
  .contact__infos .cf__info .icon__wrapper {
    min-height: 5rem;
    min-width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #d0d5dd; */
    border-radius: 0.4rem;

  }
   .contact__infos .cf__info svg{
    height: 2rem;
    width: 2rem;
    stroke-width: 1.5px;
    color: #A74C37;
  }
  .contact__infos .cf__info span{
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
.spinner {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 3px solid #ddd;
    border-right-color: rgb(110, 108, 108);
    animation: spin 0.5s linear infinite;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width:1000px) {
    .cf__container{
        grid-template-columns: 1fr;
        gap: 4rem;
    }
    .register__form__width{
        width: calc(100% - 6rem);
    }
    .contact__infos{
        margin-top: 4rem;
    }
  }