.review-wrapper .review-heading{
    font-size: 1.8rem;
    margin: 2rem 0;
}
.review-wrapper .reviewer-card{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
}
.review-wrapper .reviewer-card .reviewer-img{
    min-height: 6rem;
    min-width: 6rem;
    max-height: 6rem;
    max-width: 6rem;
    border-radius: 50%;
    /* overflow: hidden; */
}
.review-wrapper .reviewer-card .reviewer-img img{
    object-fit: cover;
    min-height: 6rem;
    min-width: 6rem;
    max-height: 6rem;
    max-width: 6rem;
    border-radius: 50%;
    
}
.reviewer-rating-message{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: calc(100% - 4rem);
}
.reviewer-rating-message .reviewer-name-star h5{
    font-size: 1.6rem;
}
.review-wrapper .reviewer-card .user-first-avatar{
    border: 1px solid #d0d5dd;
    min-height: 6rem;
    min-width: 6rem;
    max-height: 6rem;
    max-width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    font-size: 26px;
    

}
.review-wrapper .reviewer-card .user-first-avatar{}
.reviewer-message{
    font-size: 1.4rem;
}
.be-first-reviwer{
    font-size: 18px;
    font-weight: 500;

}
.reviewer-name-star{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.revie-form .review-form-heading{
    font-size: 2.2rem;
    margin-top: 1rem;

}

.message-rating textarea{
    height: 15rem;
    max-height: 15rem;
    overflow-y: auto;
    resize: none;
    font-size: 1.7rem;
    resize: none;
    padding: 1.2rem;
    border: none;
    border: 1px solid #d0d5dd;
    outline: none;
    border-radius: 6px;
    width: calc(100% - 2.4rem);
    margin-top: 0.5rem;
}
.message-rating textarea::-webkit-scrollbar{
    display: none;
}
.revie-form .add-review-button{
    padding: 1.2rem 4rem;
    margin-top: 1.2rem;
    font-size: 1.6rem;
    cursor: pointer;
    border-radius: 0.6rem;
    border: none;
    outline: none;
    background: #A74C37;
    color: #fff;
    font-weight: 600;
    width: fit-content;
}
.revie-form .add-review-button:disabled{
    cursor: not-allowed;
    background: #656582;

}

.reviewer-cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
}
@media screen and (max-width:1024px) {
    .reviewer-cards{
        grid-template-columns: repeat(auto-fit,minmax(350px,1fr));
    }
    
}
@media screen and (max-width:1000px) {
    .reviewer-cards{
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    }
    
}

