.auth__wrapper{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;

}

.auth__wrapper    .auth__container{
    width: 370px;
    max-width: 80%;
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 0.6rem;
}
.auth__header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.auth__header  .half__logo img{
    height: 6rem;
    max-width: 100%;
    object-fit: contain;

}
.auth__wrapper .auth__container h3{
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color:#121212;

}
.auth__text {
    color: #212121;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}
.form__row{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}
.input__group{
    display: flex;
    flex-direction: column;
}
.input__group.disable input{
    border: 1px solid #bababa;
    background: #f9f9f9;
    
}
.group {
    position: relative;
}
.passwordToggle , .confirmPasswordToggle ,.confirmPassword{
    position: absolute;
    top: 53%;
    right: 10px;
    cursor: pointer;
}
.passwordToggle svg,.confirmPassword{
    color: #D0D5DD;
    background-color: transparent;
}
label {
    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    display: block;
}
label span {
  color:  rgb(219, 70, 60);
  font-size: 1.5rem;
}

input ,select{
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 12px;
    width: calc(100% - 24px);
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #121212;
}
select{
    width: 100%;
}
input.is-invalid{
    border: 1px solid rgb(219, 70, 60);
}
.input__group input::placeholder{
    color: #8E97A9;

}
.forgot{
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    margin-top: 10px;
}
.forgot span {
        color:#121212;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        text-decoration: none;
        cursor: pointer;

}
.dont__text{
    color: #121212;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-top: 20px;
}
.dont__text span{
    color: #1BA5D3;
    font-weight: 500;
    cursor: pointer;
}
button{
    border-radius: 8px;
    background: #A74C37;
    padding: 10px 0;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;

}
button:hover{
    background-color: #7D2814;
}
button.disabled{
    cursor: not-allowed;
}
.spinner {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 3px solid #ddd;
    border-right-color: rgb(110, 108, 108);
    animation: spin 0.5s linear infinite;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }