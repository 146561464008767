.dr-card-wrapper-section{
    padding: 7rem 0;
}

.dr-card-wrapper-section .dr-card-wrapper-div{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1rem;
}
.dr-card-wrapper-section  .dr-not-found{
    font-size: 3.2rem;
    color: #121212;
    min-height: 30vh;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:1300px) {
    
    .dr-card-wrapper-section .dr-card-wrapper-div{
        grid-template-columns: repeat(auto-fit,minmax(330px,1fr));
        gap: 2.5rem;
    }
}
@media screen and (max-width:400px) {
    
    .dr-card-wrapper-section .dr-card-wrapper-div{
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
        gap: 2.5rem;
    }
}