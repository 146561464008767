.form__container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    width: 100%;
}
.register__form__width{
    width: 70%;
    max-width: 90%;
    background: #fff;
    padding: 3rem;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
}
.register__form__width h3{
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color:#121212;

}
.register__form__width .auth__text {
    color: #212121;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}
.gender__age__wrapper{
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
   
}

@media screen and (max-width:767px) {
    .register__form__width{
        width: 80%;
    }
    .form__container{
        grid-template-columns: 1fr;
    }
    .right__section .form__row{
        margin-top: 0;
    }
    .gender__age__wrapper{
        grid-template-columns: 1fr;
    }
    .register__form{
        padding: 5rem 0;
    }
    
}